import noImagePlaceholder from '../../../../assets/common/no-image-placeholder.png';
import facebook from '../../../../assets/jeep/icon/facebook.svg';
import facebook_small from '../../../../assets/jeep/icon/facebook_small.svg';
import instagram_small from '../../../../assets/jeep/icon/instagram_small.svg';
import instagram from '../../../../assets/jeep/icon/instagram.svg';
import linkedin from '../../../../assets/jeep/icon/linkedin.svg';
import linkedin_small from '../../../../assets/jeep/icon/linkedin_small.svg';
import tiktok from '../../../../assets/jeep/icon/tiktok.svg';
import tiktok_small from '../../../../assets/jeep/icon/tiktok_small.svg';
import twitter from '../../../../assets/jeep/icon/twitter.svg';
import twitter_small from '../../../../assets/jeep/icon/twitter_small.svg';
import youtube from '../../../../assets/jeep/icon/youtube.svg';
import youtube_small from '../../../../assets/jeep/icon/youtube_small.svg';
import open from '../../../../assets/jeep/icon/open.svg';
import phone from '../../../../assets/jeep/icon/phone.svg';
import poi from '../../../../assets/jeep/icon/poi.svg';
import usedVehicles from '../../../../assets/jeep/icon/usedcars.svg';
import jeepDefaultServiceIcon from '../../../../assets/jeep/icon/usedcars.svg';
import jeepPassengerCarsSalesIcon from '../../../../assets/jeep/icon/passengercarsales.svg';
import newVehicles from '../../../../assets/jeep/icon/passengercarsales.svg';
import jeepServiceValetIcon from '../../../../assets/jeep/icon/valet.svg';
import jeepPassengerCarsServiceIcon from '../../../../assets/jeep/icon/service.svg';
import nextArrow from '../../../../assets/jeep/icon/nextArrow.svg';
import nextArrowDark from '../../../../assets/jeep/icon/nextArrowDark.svg';
import prevArrow from '../../../../assets/jeep/icon/prevArrow.svg';
import prevArrowDark from '../../../../assets/jeep/icon/prevArrowDark.svg';
import teamMemberPlaceholder from '../../../../assets/jeep/icon/teamMemberPlaceholder.svg';
import docExtension from '../../../../assets/jeep/icon/extension.svg';
import closeIcon from '../../../../assets/jeep/icon/close-icon.svg';
import checkboxTick from '../../../../assets/jeep/icon/checkboxTick.svg';
import accordionChevron from '../../../../assets/jeep/icon/accordionChevron.svg';
import dealerLocatorArrow from '../../../../assets/jeep/icon/dealerLocatorArrow.svg';
import phoneIcon from '../../../../assets/jeep/icon/phoneIcon.svg';
import deleteIcon from '../../../../assets/jeep/icon/delete.svg';
import formDateInput from '../../../../assets/jeep/icon/form-date-input.svg';
import formTimeInput from '../../../../assets/jeep/icon/form-time-input.svg';
import downArrow from '../../../../assets/jeep/icon/downArrow.svg';
import infoIcon from '../../../../assets/jeep/icon/infoIcon.svg';

export const jeepAssets = Object.freeze({
  infoIcon,
  noImagePlaceholder,
  phoneIcon,
  downArrow,
  formTimeInput,
  formDateInput,
  nextArrow,
  prevArrow,
  facebook,
  facebook_small,
  instagram,
  instagram_small,
  linkedin,
  linkedin_small,
  tiktok,
  tiktok_small,
  twitter,
  twitter_small,
  youtube,
  youtube_small,
  open,
  phone,
  poi,
  usedVehicles,
  newVehicles,
  jeepDefaultServiceIcon,
  jeepServiceValetIcon,
  jeepPassengerCarsServiceIcon,
  jeepPassengerCarsSalesIcon,
  prevArrowDark,
  nextArrowDark,
  teamMemberPlaceholder,
  docExtension,
  closeIcon,
  checkboxTick,
  accordionChevron,
  dealerLocatorArrow,
  deleteIcon,
});
